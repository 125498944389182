import { type CountryCode } from "libphonenumber-js";

export const isSupportedSmsCountry = (country: CountryCode): boolean => {
  switch (country) {
    case "AL":
    case "AD":
    case "AT":
    case "BY":
    case "BE":
    case "BA":
    case "BG":
    case "HR":
    case "CY":
    case "CZ":
    case "DK":
    case "EE":
    case "FO":
    case "FI":
    case "FR":
    case "DE":
    case "GI":
    case "GR":
    case "HU":
    case "IS":
    case "IE":
    case "IT":
    case "XK":
    case "LV":
    case "LI":
    case "LT":
    case "LU":
    case "MK":
    case "MT":
    case "MD":
    case "MC":
    case "ME":
    case "NL":
    case "NO":
    case "PL":
    case "PT":
    case "RO":
    case "RU":
    case "SM":
    case "RS":
    case "SK":
    case "SI":
    case "ES":
    case "SE":
    case "CH":
    case "UA":
    case "GB":
    case "VA":
    case "KY":
    case "CA":
    case "US":
      return true;
    default:
      return false;
  }
};
