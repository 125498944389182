"use client";

import { useState } from "react";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import { BasicUserForm } from "@/components/BasicUserForm/BasicUserForm";
import { LocaleSelector } from "@/components/LocaleSelector";
import { useAuth } from "@/components/SignIn/SignInProvider";
import { TimezoneSelectorForm } from "@/components/TimezoneSelectorForm/TimezoneSelectorForm";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/Button";
import { SimpleDialog } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/Icon";
import { Loader } from "@/components/ui/Loader/Loader";
import { Popover } from "@/components/ui/Popover";
import { Text } from "@/components/ui/Text";
import { type PageContent } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { uniqBy } from "lodash-es";
import { signOut } from "next-auth/react";
import { useLocaleProvider } from "../LocaleProvider";
const t = {
  logIn: {
    en: "Log in",
    no: "Logg inn",
    dk: "Log ind"
  },
  signUp: {
    en: "Sign up",
    no: "Opprett bruker",
    dk: "Opret bruger"
  },
  change: {
    no: "Bytt konto",
    en: "Change account",
    dk: "Skift konto",
    business: {
      no: "Bytt til bedrift",
      en: "Change to business",
      dk: "Skift til virksomhed"
    },
    private: {
      no: "Bytt til privat",
      en: "Change to private",
      dk: "Skift til privat"
    }
  },
  create: {
    en: "Create Business",
    no: "Opprett bedrift",
    dk: "Opret virksomhed"
  },
  logOut: {
    en: "Log out",
    no: "Logg ut",
    dk: "Log ud"
  },
  roles: {
    private: {
      en: "Private",
      no: "Privat",
      dk: "Privat"
    },
    ADMIN: {
      en: "Admin",
      no: "Admin",
      dk: "Admin"
    },
    EDITOR: {
      en: "Editor",
      no: "Redigeringstilgang",
      dk: "Redigeringstilgang"
    },
    OWNER: {
      en: "Owner",
      no: "Eier",
      dk: "Ejer"
    },
    OBSERVER: {
      en: "Observer",
      no: "Observer",
      dk: "Observer"
    }
  },
  menu: {
    account: {
      no: "Kontoinnstillinger",
      en: "Account settings",
      dk: "Kontoindstillinger"
    },
    profile: {
      no: "Profil",
      en: "Profile",
      dk: "Profil"
    },
    settings: {
      no: "Kontoinnstillinger",
      en: "Account settings",
      dk: "Kontoindstillinger"
    },
    support: {
      en: "Talk to support",
      no: "Snakk med support",
      dk: "Snak med support"
    }
  }
} satisfies PageContent;
export function UserMenu({
  variant,
  expanded
}: {
  variant: "dark" | "light";
  expanded?: boolean;
}) {
  const {
    lang
  } = useLocaleProvider();
  const {
    data: user,
    isLoading
  } = trpc.user.getUserWithRoles.useQuery(undefined, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });
  const {
    setShowAuth
  } = useAuth();
  const params = useParams();
  const businessSlug = params?.businessId as string;
  const [isChangingRole, setIsChangingRole] = useState(false);
  const navigation = businessSlug ? [{
    name: t.menu.settings[lang],
    href: "/profile/candidate/settings"
  }, {
    name: t.menu.support[lang],
    id: "#open-intercom"
  }] : [{
    name: t.menu.profile[lang],
    href: "/profile/candidate"
  }, {
    name: t.menu.settings[lang],
    href: "/profile/candidate/settings"
  }, {
    name: t.menu.support[lang],
    id: "#open-intercom"
  }];
  const router = useRouter();
  if (isLoading) {
    return <div className="relative flex gap-2">
        <Loader />
      </div>;
  }
  if (!user) {
    return <div className="flex gap-4">
        <Button variant={variant === "light" ? "white" : "black"} onClick={() => {
        setShowAuth({
          type: "default"
        });
      }} fancy className={variant === "dark" ? "border-white bg-primary-dark text-white hover:drop-shadow-[4px_4px_0px_white]" : ""}>
          {t.logIn[lang]}
        </Button>
        <Button variant={variant === "dark" ? "white" : "black"} fancy className={cn("hidden md:block", variant === "dark" ? "border-primary-dark hover:drop-shadow-[4px_4px_0px_white]" : "")} onClick={() => {
        setShowAuth({
          type: "default"
        });
      }}>
          {t.signUp[lang]}
        </Button>
      </div>;
  }
  const businessRoles = uniqBy(user.companyRoles.map(role => ({
    id: role.company?.slug || role.listing?.company.slug || "",
    name: role.company?.name || role.listing?.company.name || "",
    image: role.company?.logoUrl || role.listing?.company.logoUrl || "",
    role: role.level
  })).sort((a, b) => b.name.localeCompare(a.name)).sort(el => ["ADMIN", "OWNER"].includes(el.role) ? -1 : 1), e => e.id);
  return <>
      {!businessSlug && businessRoles.length > 0 ? <Link href={`/profile/business/${businessRoles[0]!.id}`} className="hidden md:block">
          <Button variant={variant === "light" ? "black" : "white"} className={variant === "dark" ? "border-white bg-primary-dark text-white hover:drop-shadow-[4px_4px_0px_white]" : ""}>
            {t.change.business[lang]}
          </Button>
        </Link> : null}
      {user.firstName.trim() === "" || user.lastName.trim() === "" ? <SimpleDialog open>
          <BasicUserForm />
        </SimpleDialog> : <TimezoneSelectorForm />}
      <div className={expanded ? "relative w-full" : "relative"}>
        <Popover hideClose hideArrow wrapperClassName={"z-30 mx-4 focus-visible:outline-none drop-shadow-lg"} sideOffset={10} content={<div className="relative z-30 w-64 rounded bg-white py-2 shadow transition-all">
              {isChangingRole ? <>
                  {businessRoles.map(businessRole => {
            return <RoleCard {...businessRole} onClick={() => {
              router.push(`/profile/business/${businessRole.id}`);
              setIsChangingRole(false);
            }} role={t.roles[businessRole.role][lang]} selected={isChangingRole ? businessSlug === businessRole.id : undefined} key={businessRole.id} />;
          })}

                  <RoleCard {...user} onClick={() => {
            router.push("/profile/candidate");
            setIsChangingRole(false);
          }} role={t.roles.private[lang]} selected={isChangingRole ? businessSlug === undefined : undefined} />
                </> : <RoleCard className="pb-8" {...businessRoles.find(el => el.id === businessSlug) || user} role={t.roles[businessRoles.find(el => el.id === businessSlug)?.role || "private"][lang]} email={user.email} onClick={() => {
          if (businessRoles.length > 0) {
            setIsChangingRole(true);
          }
        }} selected={isChangingRole ? businessSlug === undefined : undefined} extra={businessRoles.length > 0 ? <Text.P className="underline">{t.change[lang]}</Text.P> : undefined} />}
              {navigation.map(el => <Link href={"href" in el ? el.href! : "#"} id={"id" in el ? el.id : undefined} key={el.name}>
                  <div className="px-4 py-4 hover:bg-primary-lighter">{el.name}</div>
                </Link>)}
              <div className="mx-2 border-t border-stroke-gray px-2" />
              <Link href="/onboarding">
                <div className="px-4 py-4 hover:bg-primary-lighter">{t.create[lang]}</div>
              </Link>
              {businessSlug && <>
                  <div className="mx-2 border-t border-stroke-gray px-2" />
                  <div className="my-4 px-4">
                    <LocaleSelector inverted={false} />
                  </div>
                </>}
              <div className="mx-2 border-t border-stroke-gray px-2" />
              <a id="logout" onClick={() => signOut()} className="cursor-pointer" tabIndex={0}>
                <div className="px-4 py-4 hover:bg-primary-lighter">{t.logOut[lang]}</div>
              </a>
            </div>} data-sentry-element="Popover" data-sentry-source-file="UserMenu.tsx">
          {expanded ? <div className="flex w-full cursor-pointer items-center gap-2 rounded border border-white p-2" tabIndex={1}>
              <Avatar className="h-10 w-10">
                <AvatarImage src={user.image} alt={user.name + " avatar"} />
                <AvatarFallback>
                  {`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1 space-y-1">
                <Text.P strong className="truncate">
                  {user.name}
                </Text.P>
                <Text.P className="truncate">
                  {user.companyRoles.find(el => el.company?.slug === businessSlug)?.company?.name}
                </Text.P>
              </div>
              <Icon icon="downChevron" size="x-small" variant="ghost-inverted" />
            </div> : <Avatar className="h-10 w-10 cursor-pointer">
              <AvatarImage src={user.image} alt={user.name + " avatar"} />
              <AvatarFallback>
                {`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
              </AvatarFallback>
            </Avatar>}
        </Popover>
      </div>
    </>;
}
const RoleCard = (props: {
  selected?: boolean;
  onClick: () => void;
  extra?: React.ReactNode;
  id: string;
  className?: string;
  name: string;
  email?: string;
  image: string | null;
  role: string;
}) => {
  return <div onClick={props.onClick} key={props.id} className={cn("mx-2 flex cursor-pointer items-center gap-2 border-b border-stroke-gray px-2 py-3", props.className)} data-sentry-component="RoleCard" data-sentry-source-file="UserMenu.tsx">
      <Avatar className="h-8 w-8 self-start" data-sentry-element="Avatar" data-sentry-source-file="UserMenu.tsx">
        <AvatarImage src={props.image || ""} alt={props.name + " avatar"} data-sentry-element="AvatarImage" data-sentry-source-file="UserMenu.tsx" />
        <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="UserMenu.tsx">
          {props.name.split(" ").map(el => el.charAt(0)).join("").toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-1 flex-col space-y-1 overflow-hidden">
        <Text.P strong big data-sentry-element="unknown" data-sentry-source-file="UserMenu.tsx">
          {props.name}
        </Text.P>
        {props.email && <Text.P muted className="truncate">
            {props.email}
          </Text.P>}
        <Text.P data-sentry-element="unknown" data-sentry-source-file="UserMenu.tsx">{props.role}</Text.P>
        {props.extra}
      </div>
      {props.selected !== undefined && <Icon size="small" variant={props.selected ? "primary" : "gray"} icon={props.selected ? "check-circle" : "empty-circle"} />}
    </div>;
};