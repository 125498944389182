export const SEARCH_PARAMS_VOUCH_ID = "vouch";
export const SEARCH_PARAMS_LISTING_ID = "listing";
export const SEARCH_PARAMS_SHOW_GIVEN_KEY = "showGiven";
export const SEARCH_PARAMS_NEWSLETTER_OPT_OUT = "newsletterOptOut";
export const SEARCH_PARAMS_SHOW_ACCEPT_FORM_KEY = "acceptVouch";
export const SEARCH_PARAMS_PREVIEW = "preview";
export const SEARCH_PARAMS_SORT_KEY = "sort";
export const SEARCH_PARAMS_QUERY_KEY = "query";
export const SEARCH_PARAMS_FILTER_KEY = "filter";
export const SEARCH_PARAMS_TAB_KEY = "tab";
export const SEARCH_PARAMS_FIRST_NAME_KEY = "first_name";
export const SEARCH_PARAMS_LAST_NAME_KEY = "last_name";
export const SEARCH_PARAMS_COMPANY_ID = "company_id";
export const SEARCH_PARAMS_PENDING_COMPANY_ID = "pending_company_id";
export const SEARCH_PARAMS_INDICATED_PLAN_AT_SIGN_UP = "plan";