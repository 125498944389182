"use client";

import * as React from "react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/Command";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/Drawer";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/Popover2";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/utils/tw";
import { ChevronsUpDown } from "lucide-react";
export function ComboBoxRemoteItems(props: {
  trigger: React.ReactNode;
  itemList: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  if (isDesktop) {
    return <Popover open={props.open} onOpenChange={props.setOpen}>
        <PopoverTrigger asChild>{props.trigger}</PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popper-anchor-width)] p-0" align="start">
          {props.itemList}
        </PopoverContent>
      </Popover>;
  }
  return <Drawer open={props.open} onOpenChange={props.setOpen} data-sentry-element="Drawer" data-sentry-component="ComboBoxRemoteItems" data-sentry-source-file="Combobox.tsx">
      <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="Combobox.tsx">{props.trigger}</DrawerTrigger>
      <DrawerContent data-sentry-element="DrawerContent" data-sentry-source-file="Combobox.tsx">
        <div className="mt-4 border-t">{props.itemList}</div>
      </DrawerContent>
    </Drawer>;
}
export function ComboBoxDynamic<TOption = Record<string, unknown>>({
  labelKey = "label" as keyof TOption,
  valueKey = "value" as keyof TOption,
  triggerClassName,
  ...props
}: {
  options: TOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  labelKey?: keyof TOption;
  valueKey?: keyof TOption;
  uniqueKey?: keyof TOption;
  defaultValue?: TOption;
  triggerClassName?: string;
  extraKeywords?: string[];
  onSelectCallback: (option: TOption) => void;
  constructLabel?: (option: TOption) => string;
}) {
  const uniqueKey = props.uniqueKey ?? valueKey;
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<TOption | null>(props.defaultValue ?? null);
  return <ComboBoxRemoteItems open={open} setOpen={setOpen} trigger={<button className={cn("flex h-10 w-full items-center justify-between rounded-md border border-border bg-white px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1", triggerClassName)}>
          {props.constructLabel && selectedOption ? props.constructLabel(selectedOption) : String(selectedOption?.[labelKey] ?? props.placeholder ?? "Select")}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>} itemList={<Command>
          <CommandInput placeholder={props.searchPlaceholder ?? "Search"} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {props.options.map(option => <CommandItem key={String(option[uniqueKey])} keywords={[String(option[labelKey]), ...(props.extraKeywords ?? [])]} value={String(option[valueKey])} onSelect={_value => {
          setSelectedOption(option);
          setOpen(false);
          props.onSelectCallback(option);
        }}>
                  {props.constructLabel ? props.constructLabel(option) : String(option[labelKey])}
                </CommandItem>)}
            </CommandGroup>
          </CommandList>
        </Command>} data-sentry-element="ComboBoxRemoteItems" data-sentry-component="ComboBoxDynamic" data-sentry-source-file="Combobox.tsx" />;
}