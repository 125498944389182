"use client";

import { env } from "@/env.mjs";

export const FB_PIXEL_ID = env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
  }
}

export const pageview = () => {
  if (env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
    window.fbq("track", "PageView");
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if (env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
    window.fbq("track", name, options);
  }
};
