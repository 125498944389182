import { z } from "zod";

export const mediaPosition = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
});

export const zContentMediaSectionItem = z.object({
  kind: z.union([z.literal("image"), z.literal("video")]),
  url: z.string(),
  crop: mediaPosition.optional(),
});

const zCompanyTextSectionContent = z.object({
  version: z.literal(1),
  kind: z.literal("text"),
  title: z.string(),
  content: z.string(),
  media: z.array(zContentMediaSectionItem).max(20).optional(),
});

export type CompanyTextSectionContent = z.infer<typeof zCompanyTextSectionContent>;

export const zCompanyGridSectionContent = z.object({
  version: z.literal(1),
  kind: z.literal("grid"),
  title: z.string(),
  content: z.string().optional(),
  media: z.array(zContentMediaSectionItem).max(20).optional(),
  items: z
    .array(
      z.object({
        content: z.string(),
        image: z.string().optional(),
        emoji: z.string().optional(),
      }),
    )
    .max(20),
});
export type CompanyGridSectionContent = z.infer<typeof zCompanyGridSectionContent>;

export const zCompanyTimelineSection = z.object({
  version: z.literal(1),
  kind: z.literal("timeline"),
  items: z
    .array(
      z.object({
        key: z.number(),
        content: z.string(),
      }),
    )
    .max(20),
});
export type CompanyTimelineSection = z.infer<typeof zCompanyTimelineSection>;

export const zCompanyTestimonialSection = z.object({
  version: z.literal(1),
  kind: z.literal("testimonial"),
  items: z
    .array(
      z.object({
        name: z.string(),
        subtitle: z.string(),
        testimonial: z.string(),
      }),
    )
    .max(20),
});
export type CompanyTestimonialSection = z.infer<typeof zCompanyTestimonialSection>;

export const zCompanyNumbersSectionContent = z.object({
  version: z.literal(1),
  kind: z.literal("numbers"),
  title: z.string(),
  content: z.string().optional(),
  items: z
    .array(
      z.object({
        number: z.string(),
        subtitle: z.string(),
      }),
    )
    .max(20),
});

export type CompanyNumbersSectionContent = z.infer<typeof zCompanyNumbersSectionContent>;

export const zCompanySocialMediaSection = z.object({
  version: z.literal(1),
  kind: z.literal("socialMedia"),
  linkedIn: z.string().optional(),
  facebook: z.string().optional(),
  instagram: z.string().optional(),
  twitter: z.string().optional(),
  tiktok: z.string().optional(),
  youtube: z.string().optional(),
});

export type CompanySocialMediaSection = z.infer<typeof zCompanySocialMediaSection>;

export const zCompanyMediaSection = z.object({
  version: z.literal(1),
  kind: z.literal("media"),
  media: z.array(zContentMediaSectionItem).max(20),
});

export type CompanyMediaSection = z.infer<typeof zCompanyMediaSection>;

export const zCompanySectionContentInput = z.union([
  zCompanyTextSectionContent,
  zCompanyGridSectionContent,
  // zCompanyTimelineSection,
  // zCompanyTestimonialSection,
  zCompanyNumbersSectionContent,
  // zCompanyMediaSection,
  // zCompanySocialMediaSection,
]);

export const zCompanySectionContent = zCompanySectionContentInput.and(z.object({ id: z.string() }));

export type CompanySectionContent =
  | CompanyTextSectionContent
  | CompanyGridSectionContent
  // | CompanyTimelineSection
  // | CompanyTestimonialSection
  // | CompanyMediaSection
  // | CompanySocialMediaSection
  | CompanyNumbersSectionContent;
