import Link from "next/link";
import { type PageContent, type SupportedLanguage } from "@/utils/i18n";
import { Button } from "../ui/Button";
import { Icon } from "../ui/Icon";
import { Text } from "../ui/Text";
const t = {
  title: {
    no: "Sjekk innboksen",
    en: "Check your inbox",
    dk: "Tjek din indbakke"
  },
  description: {
    no: `Vi har sendt en bekreftelsesmail til eposten din, gå til innboksen for å bekrefte at vi har riktig epost. Hvis du ikke finner mailen, sjekk søppelpostmappen.`,
    en: `We have sent a confirmation email to your email, go to your inbox to confirm that we have the correct email. If you can't find the email, check your spam folder.`,
    dk: `Vi har sendt en bekræftelsesmail til din e-mail, gå til din indbakke for at bekræfte, at vi har den korrekte e-mail. Hvis du ikke kan finde e-mailen, skal du tjekke din spam-mappe.`
  },
  gmail: {
    no: "Åpne Gmail",
    en: "Open Gmail",
    dk: "Åbn Gmail"
  },
  outlook: {
    no: "Åpne Outlook",
    en: "Open Outlook",
    dk: "Åbn Outlook"
  }
} satisfies PageContent;
export const VerifyingAuthScreen = ({
  email,
  lang
}: {
  email: string;
  lang: SupportedLanguage;
}) => {
  return <div className="flex flex-col items-center gap-6" data-sentry-component="VerifyingAuthScreen" data-sentry-source-file="VerifyingAuthScreen.tsx">
      <div className="my-4">
        <Icon icon="email" size="x-large" data-sentry-element="Icon" data-sentry-source-file="VerifyingAuthScreen.tsx" />
      </div>
      <Text.H4 data-sentry-element="unknown" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.title[lang]}</Text.H4>
      <Text.P data-sentry-element="unknown" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.description[lang]}</Text.P>
      <Link href={`https://mail.google.com/mail/u/${email}/#search/from%3A%40vouch.careers+in%3Aanywhere+newer_than%3A1d`} target="_blank" data-sentry-element="Link" data-sentry-source-file="VerifyingAuthScreen.tsx">
        <Button size="lg" data-sentry-element="Button" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.gmail[lang]}</Button>
      </Link>
      <Link href={`https://outlook.live.com/mail/?login_hint=${encodeURIComponent(email)}`} target="_blank" data-sentry-element="Link" data-sentry-source-file="VerifyingAuthScreen.tsx">
        <Button size="lg" data-sentry-element="Button" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.outlook[lang]}</Button>
      </Link>
    </div>;
};