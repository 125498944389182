"use client";

import * as React from "react";
import { cn } from "@/utils/tw";
import { Dialog as Dialog2, Transition } from "@headlessui/react";
import { noop } from "lodash-es";
import { Button } from "./Button";
function isElementChild(child: HTMLElement, parent: HTMLElement): boolean {
  return parent.contains(child);
}
export const SimpleDialog = ({
  open = false,
  onClose,
  children,
  className,
  wrapperClassName,
  transparent = false,
  title
}: {
  children: React.ReactNode;
  open?: boolean;
  title?: React.ReactNode;
  wrapperClassName?: string;
  onClose?: () => void;
  className?: string;
  transparent?: boolean;
}) => {
  React.useEffect(() => {
    const htmlRoot = document.getElementsByTagName("html")[0];
    if (document && htmlRoot) {
      /*
         To avoid potential crashes of null/undefined selections, you may want 
         to save the selection as a variable and check if the selection exists  
         before attempting to add a style. For example, type guarding the 
         selection to make sure its defined before attempting to access 
         Element properties:
           const node = document.getElementById("container");
         if (node) node.style = "...";
      */
      if (open) {
        htmlRoot.style.overflow = "hidden";
      } else {
        htmlRoot.style.overflow = "";
      }
      return () => {
        htmlRoot.style.overflow = "";
      };
    }
  }, [open]);
  // const [currMaxHeight, setCurrMaxHeight] = React.useState<number | undefined>(
  //   typeof window !== "undefined" ? window.visualViewport?.height : 0,
  // );

  const containerRef = React.useRef<HTMLDivElement>(null);
  // React.useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const handleResize = (e: { target: unknown }) => {
  //       if (Math.floor(window.innerHeight) === Math.floor((e.target as VisualViewport).height)) {
  //         setCurrMaxHeight((e.target as VisualViewport).height);
  //       }
  //     };
  //     window.visualViewport?.addEventListener("resize", handleResize);
  //     return () => {
  //       if (window.visualViewport)
  //         window.visualViewport.removeEventListener("resize", handleResize);
  //     };
  //   }
  // }, []);
  return <Transition show={open} as={React.Fragment} data-sentry-element="Transition" data-sentry-component="SimpleDialog" data-sentry-source-file="dialog.tsx">
      <Dialog2 ref={containerRef} open={open} onClose={noop} className="relative z-40" onClick={event => {
      /**
       * disable Close modal when click outside of modal
       */
      if (isElementChild(containerRef.current!, event.target as HTMLElement)) {
        onClose?.();
      }
    }} data-sentry-element="Dialog2" data-sentry-source-file="dialog.tsx">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">
          <div className="fixed inset-0 z-50 bg-black/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" aria-hidden="true" style={{
          // maxHeight: currMaxHeight !== 0 ? currMaxHeight : undefined,
        }} />
        </Transition.Child>

        <div className={cn("fixed bottom-0 left-[50%] top-auto z-50 grid h-auto w-full translate-x-[-50%] translate-y-0 gap-4 overflow-auto duration-200 md:bottom-auto md:top-[50%] md:w-full md:max-w-4xl md:translate-y-[-50%] md:rounded-lg", wrapperClassName)}>
          <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-40 bg-white/10" enterTo={cn("scale-100 opacity-100", transparent ? "" : "bg-white")} leave="ease-in duration-200" leaveFrom={cn("scale-100 opacity-100", transparent ? "" : "bg-white")} leaveTo="opacity-0 scale-40 bg-white/10" data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">
            <Dialog2.Panel className={cn("relative mx-0 flex h-dvh w-full flex-col justify-center overflow-y-auto rounded border shadow-lg transition-all md:h-auto md:max-h-screen", transparent ? "" : "bg-white")} style={{
            // maxHeight: currMaxHeight !== 0 ? currMaxHeight : undefined,
          }} data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">
              {(title || onClose) && <div className="flex w-full px-4 pt-4 md:px-8 md:pt-8">
                  <div className="flex flex-1 items-center">{title}</div>

                  {!onClose ? null : <div className="">
                      <Button icon="close" variant="outline-muted" size="sm" onClick={() => onClose()} />
                      <span className="sr-only">Close</span>
                    </div>}
                </div>}
              <div className={cn("flex flex-col gap-2 overflow-y-auto px-4 pb-4 pt-2 md:px-8 md:pb-8", className)}>
                {children}
              </div>
            </Dialog2.Panel>
          </Transition.Child>
        </div>
      </Dialog2>
    </Transition>;
};