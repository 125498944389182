"use client";

// Shad CN base form components
// Individual FormInput elements can be found in formInputs/TextFormField.tsx etc. These components need to be used inside a Shad Form setup
import type * as LabelPrimitive from "@radix-ui/react-label";
import type { ControllerFieldState, ControllerProps, ControllerRenderProps, FieldPath, FieldValues, UseFormStateReturn } from "react-hook-form";
import * as React from "react";
import { Label } from "@/components/ui/Label";
import { cn } from "@/utils/tw";
import { Slot } from "@radix-ui/react-slot";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
const Form = FormProvider;
type FormFieldContextValue<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
  name: TName;
};
const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);
const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  props.control;
  props.name;
  return <FormFieldContext.Provider value={{
    name: props.name
  }} data-sentry-element="unknown" data-sentry-component="FormField" data-sentry-source-file="Form.tsx">
      <Controller {...props} data-sentry-element="Controller" data-sentry-source-file="Form.tsx" />
    </FormFieldContext.Provider>;
};
const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const {
    getFieldState,
    formState
  } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }
  const {
    id
  } = itemContext;
  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};
type FormItemContextValue = {
  id: string;
};
const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);
const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  className,
  ...props
}, ref) => {
  const id = React.useId();
  return <FormItemContext.Provider value={{
    id
  }}>
        <div ref={ref} className={cn("space-y-1 md:space-y-2", className)} {...props} />
      </FormItemContext.Provider>;
});
FormItem.displayName = "FormItem";
const FormLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(({
  className,
  ...props
}, ref) => {
  const {
    error,
    formItemId
  } = useFormField();
  return <Label ref={ref} className={cn(error && "text-destructive", className)} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = "FormLabel";
const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(({
  ...props
}, ref) => {
  const {
    error,
    formItemId,
    formDescriptionId,
    formMessageId
  } = useFormField();
  const [isMounted, setIsMounted] = React.useState(false);

  // This is a side effect that runs after the first render and sets the isMounted state to true
  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  // This is a conditional rendering that returns null if the component is not mounted yet
  if (!isMounted) {
    return null;
  }
  return <Slot ref={ref} id={formItemId} aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`} aria-invalid={!!error} {...props} />;
});
FormControl.displayName = "FormControl";
const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  ...props
}, ref) => {
  const {
    formDescriptionId
  } = useFormField();
  return <p ref={ref} id={formDescriptionId} className={cn("text-sm text-muted-foreground", className)} {...props} />;
});
FormDescription.displayName = "FormDescription";
const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  children,
  ...props
}, ref) => {
  const {
    error,
    formMessageId
  } = useFormField();
  const body = error ? String(error?.message) : children;
  if (!body) {
    return null;
  }
  return <p ref={ref} id={formMessageId} className={cn("break-all text-sm font-medium text-destructive", className)} {...props}>
      {body}
    </p>;
});
FormMessage.displayName = "FormMessage";

// const FormFieldWithRender = <
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
// >({
//   control,
//   name,
//   label,
//   description,
//   placeholder,
//   ...props
// }: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
//   label: string;
//   description?: string;
//   placeholder?: string;
//   children?: React.ReactNode;
// }) => {
//   return (
//     <FormField
//       control={control}
//       name={name}
//       {...props}
//       render={({ field }) => (
//         <FormItem>
//           <FormLabel>{label}</FormLabel>
//           <FormControl>
//             {React.cloneElement(props.children as React.ReactElement, {
//               placeholder,
//               ...field,
//             })}
//           </FormControl>
//           {description && <FormDescription>{description}</FormDescription>}
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   );
// };

export const FormControllerWithRender = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  name,
  label,
  description,
  inputElement,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  label: string;
  description?: string;
  inputElement: (renderProps: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
}) => {
  return <Controller control={control} name={name} {...props} render={renderProps => <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>{inputElement(renderProps)}</FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>} data-sentry-element="Controller" data-sentry-component="FormControllerWithRender" data-sentry-source-file="Form.tsx" />;
};
export const FormFieldWithRenderChildren = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  name,
  label,
  description,
  children,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  label: string;
  description?: string;
  children: (renderProps: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
}) => {
  return <Controller control={control} name={name} {...props} render={renderProps => <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>{children(renderProps)}</FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>} data-sentry-element="Controller" data-sentry-component="FormFieldWithRenderChildren" data-sentry-source-file="Form.tsx" />;
};
export const FormFieldWithRender = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  control,
  name,
  label,
  description,
  inputElement,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  label: string;
  description?: string;
  inputElement: (renderProps: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
}) => {
  return <FormField control={control} name={name} {...props} render={renderProps => <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>{inputElement(renderProps)}</FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>} data-sentry-element="FormField" data-sentry-component="FormFieldWithRender" data-sentry-source-file="Form.tsx" />;
};
export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };